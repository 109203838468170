body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  // font-family: Arial, sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #0e2841;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 999;
}

// hide updown arrows in input type number
input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.tabs-type-1 {
  .nav-item {
    margin: 0;

    .nav-link {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
      color: #d9d9d9;

      &.active {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.slider-button-index {
  left: 300px;
  top: 48px;

  .btn-index-primary {
    border: 1px solid $border-color;
    background-color: white;
    color: #8d8d8d;
    font-size: 14px;
  }

  .btn-index-primary-active {
    background-color: #0091f7;
    font-size: 14px;
    color: white;
  }
}

.dropdown-toggle-remove {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    max-height: 167px;
    overflow: auto;
  }
}

.table-select-remove {
  background-image: none;
}

.hover-table-type {
  .table-hover {
    tbody {
      tr {
        &:hover {
          background-color: #fffbfa;
        }

        td {
          padding: 16px 12px;
        }
      }
    }
  }
}

.group-button-line {
  .btn {
    color: #8d8d8d;
    background-color: #f5f5f5;
    border-color: $border-color;

    &.active {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
  }
}

.datepicker-staff {
  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    input {
      color: $primary;
      font-weight: 500;
    }

    &::after {
      content: "";
      /* This creates the icon */
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-image: url("../icons/calander.svg");
      background-size: cover;
    }

    .react-datepicker__close-icon {
      display: none;
    }

    input {
      padding: 0.75rem 1.25rem;
      padding-right: 2rem;
      line-height: 1.3;
      background-color: #ffffff;
      border-radius: 0.375rem;
      box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
      border: 1px solid #e7eaf0;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    width: 100%;
    border: 0;
    box-shadow: 4px 4px 20px 0px #00000014;

    box-shadow: -4px -4px 20px 0px #00000014;

    font-family: "Inter", sans-serif;

    .react-datepicker__navigation {
      margin-top: 18px;

      &.react-datepicker__navigation--previous {
        left: 14px;
      }

      &.react-datepicker__navigation--next {
        right: 14px;
      }
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        background-color: transparent;
        border: 0;
        padding: 24px;

        .react-datepicker__current-month {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        .react-datepicker__day-names {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          .react-datepicker__day-name {
            font-size: 14px;
            font-weight: 600;
            width: 32px;
            height: 32px;
            margin: 0px;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .react-datepicker__month {
        margin: 0px;
        padding: 0px 24px 24px 24px;

        .react-datepicker__week {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-bottom: 8px;

          .react-datepicker__day {
            font-size: 14px;
            color: #353535;
            font-weight: 600;
            width: 32px;
            height: 32px;
            margin: 0px;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            &.react-datepicker__day--in-range {
              background-color: #e6f4fe;
              color: #0091f7;
            }

            &.react-datepicker__day--selected {
              background-color: #e6f4fe;
              color: #0091f7;
            }

            &.react-datepicker__day--keyboard-selected {
              background-color: #e6f4fe;
              color: #0091f7;
            }
          }
        }
      }
    }
  }
}

.table-responsive {
  .table {
    tbody {
      tr {
        &:hover {
          .text-secondary {
            &.h5 {
              &.opacity-0 {
                opacity: 1 !important; // Show LiaPenSolid on hover
              }
            }
          }
        }
      }
    }
  }
}

.small-fullcalendar {
  --fc-border-color: transparent;
  --fc-button-border-color: transparent;
  --fc-button-hover-border-color: transparent;
  --fc-today-bg-color: transparent;
  --fc-event-bg-color: none;
  --fc-event-border-color: none;

  .fc {
    .fc-header-toolbar {
      justify-content: space-around;

      .fc-toolbar-chunk {
        .fc-next-button,
        .fc-prev-button {
          border-color: transparent;
          padding: 0;
          background-color: transparent;
          font-size: 1rem;
          color: $dark;
        }

        .fc-toolbar-title {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .fc-view-harness {
      .fc-view {
        .fc-scrollgrid {
          .fc-scrollgrid-section-header {
            .fc-col-header-cell-cushion {
              font-size: 16px;
              font-weight: 700;
              display: flex;
              justify-content: center;
            }
          }

          .fc-scrollgrid-section-body {
            .fc-day {
              border: none;

              &.fc-day-today {
                .fc-daygrid-day-top {
                  .fc-daygrid-day-number {
                    .fc-daygrid-day-top {
                      background-color: $primary;
                      color: white;
                    }
                  }
                }
              }

              .fc-daygrid-day-top {
                display: flex;
                min-height: 1rem;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                cursor: pointer;

                .fc-daygrid-day-number {
                  display: flex;
                  justify-content: center;
                }
              }

              .fc-daygrid-day-events {
                min-height: auto;
              }
            }
          }
        }
      }
    }
  }
}

.overlap-1045 {
  z-index: 1045;
}

.enquiry-type-hot {
  background-color: #d94f23 !important;
}

.enquiry-type-warn {
  background-color: #ffc107 !important;
}

.enquiry-type-cold {
  background-color: $primary !important;
}

.graph-design-custome {
  .domainAndTicks {
    .tick {
      line {
        display: none;
      }

      .tickLabel {
        font-size: 12px !important;
        fill: $dark !important;
      }
    }
  }
}

// date picker open
.rdtPicker {
  // position: relative !important;
  border: 1px solid $gray-300 !important;

  .rdtCounters {
    .rdtCounterSeparator {
      line-height: 57px;
    }

    .rdtCounter {
      height: auto;
      font-size: 12px;

      .rdtBtn {
        background-color: none;
        color: $gray;
        height: 20px;
        width: 40px;
        line-height: normal;
      }

      .rdtCount {
        line-height: normal;
      }
    }
  }
}

.chat-bubble {
  position: absolute;
  // top: 43%;
  // top: 438px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  background-color: #dcf8c6; /* Background color of chat bubble */
  border-radius: 0px 20px 20px 20px; /* Adjust border radius to style chat bubble */
  padding: 10px; /* Adjust padding as needed */
  // width: 276px;
  // max-width: 70%; /* Adjust maximum width of chat bubble */
  margin: -630px 40px;
}

.chat-content {
  text-align: left;
  color: #000000; /* Text color */
  font-size: 14px;
  word-wrap: break-word;
}

.sms-bubble {
  position: absolute;
  // top: 43%;
  // top: 438px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  background-color: #ffffff; /* Background color of chat bubble */
  border-radius: 0px 20px 20px 20px; /* Adjust border radius to style chat bubble */
  padding: 10px; /* Adjust padding as needed */
  width: 276px;
  // max-width: 70%; /* Adjust maximum width of chat bubble */
  margin: -482px 40px;
}

.sms-content {
  text-align: left;
  color: #000000; /* Text color */
  // font-size: 14px;
  font-weight: bolder;
  word-wrap: break-word;
}

.bg-grey {
  background-color: #f6f9fc;
}

.main-reports-tabs {
  .nav-item {
    margin: 0px !important;
    width: auto;
    background-color: #fbfbfb;
    .nav-link {
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 600;
      color: #8d8d8d;
      //   width: auto;
      min-width: 100px;
      border: 0.5px solid #8d8d8d55 !important;
      //   border-color: #8d8d8d55 !important;

      &.active {
        color: #ffffff;
        background-color: #0091f7;
      }
    }
  }
}
.sub-reports-tabs {
  // overflow: hidden;
  // overflow-x: auto;
  .nav-item {
    margin: 0px !important;
    width: auto;
    background-color: transparent;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    .nav-link {
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 600;
      color: #353535;
      width: auto;
      border: 0.5px solid #8d8d8d55 !important;
      border-radius: 4px;
      //   border-color: #8d8d8d55 !important;

      &.active {
        color: #ffffff;
        background-color: #0091f7;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-dropdown {
  pointer-events: none; /* Prevent clicking */
  opacity: 1 !important; /* Make it appear disabled */
  background-color: #e7eaf0 !important; /* Match the disabled form control background */
}

.rtl-currency {
  direction: rtl;
  unicode-bidi: isolate;
}


// Product module
.image-preview-container {
  width: 72px; // Adjust size as needed
  height: 72px;
  border: 1px solid #0091F7;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  text-align: center;
  color: #aaa;
  font-size: 14px;
}

.icon-camera {
  width: 24px; // Adjust size as needed
  height: 24px;
  color: #333;
  background-color: #0091F7;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


/////////////// New POS design

// Grid layout

.main-container {
  display: grid;
  grid-template-rows: auto 1fr; /* Header + Main Content */
  // height: 100vh;
  height: calc(100vh - 72px);
  // height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // gap: 10px;
  max-height: 100%; /* Fill remaining space */
  overflow: hidden; /* Prevent scrolling the entire page */
  
}

.left-section {
  display: flex;
  flex-direction: column;
  overflow: hidden; ////// /* Prevent overflow from left-section itself */ 
}

.right-section {
  display: flex;
  flex-direction: column;
  overflow: hidden; ////// /* Prevent overflow from left-section itself */ 
}

.scrollable-content {
  flex: 1; /* Takes up remaining height within its container */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}




.vertical-tabs-container {
  // height: 100vh;
  border: 1px solid #ddd;
  // overflow-y: auto; //////
  // height: 100%; //////
}

.tabs-nav {
  background-color: #f8f9fa;
  // padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto; /* Enables vertical scrolling */
}

.tabs-nav .nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
  // padding: 10px 15px;
  border-radius: 5px;
  color: #333;
  font-size: 14px;
}

.tabs-nav .nav-link.active {
  background-color: #007bff;
  color: white;
}

.tabs-nav .icon {
  font-size: 20px;
}
.tab-content {
  padding: 0px;
  overflow-y: auto; //////
}
.tabs-content {
  // padding: 20px;
  overflow-y: auto;
  // background-color: #ffffff;
}

.tabs-content h3 {
  margin-bottom: 10px;
  color: #333;
}

// listing input CSS
.floating-label-group {
  position: relative;

  // .form-control {
  //   padding-top: 1.2rem; /* Adjust space for the label */
  // }

  .floating-label {
    position: absolute;
    top: -0.6rem; /* Position on top of the input */
    left: 0.75rem; /* Align with input padding */
    background: white; /* Match background of input */
    padding: 0 0.25rem; /* Add padding around text */
    font-size: 0.75rem; /* Smaller font for labels */
    color: #888; /* Neutral color for label */
    transform: translateY(0);
    z-index: 1;
  }
}



// /////////////
.my-ui-component {
  .tabs-type-1 {
    .nav-link {
      text-align: center;
    }
  }

  .vertical-tabs-container {
    height: calc(100vh - 150px); // Adjust based on your header/footer
    overflow: hidden;

    .tabs-nav {
      overflow-y: auto;
      max-height: 100%;
    }

    .tabs-content {
      overflow-y: auto;
      max-height: 100%;
    }
  }

  .scrollable-content {
    overflow-y: auto;
    // max-height: 300px; // Adjust as needed
  }

  .icon {
    img {
      max-width: 32px;
      margin: 0 auto;
    }
  }

  .label {
    font-size: 14px;
  }
}

.fade:not(.show) {
  display: none;
}

.text-truncate-2-lines {
  display: -webkit-box;
  line-clamp: 2; /* Limits to 2 lines */
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow text wrapping */
}