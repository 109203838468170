//import the functions & variables
@import "@webpixels/css/core/functions";
@import "@webpixels/css/core/variables";
// Theam chnages
// $body-color: $neutral-primary;
// $font-family-base: "Montserrat", sans-serif;
// $display-font-family: $font-family-base;
$font-size-base: 1rem;
$font-weight-base: 400;
// $body-bg: $sand-3;

// Card
$card-border-radius: 8px;

//border
$border-color: #E1E1E1;

// Button
$btn-padding-y: 12px;
$btn-padding-x: 16px;
$btn-line-height: 1.5;
$btn-border-radius: 4px;
$btn-font-weight: 600;
$btn-box-shadow: none;

$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 24px;
$btn-font-size-lg: 16px;
$btn-border-radius-lg: 4px;

$btn-secondary-bg: $danger-light;
$btn-secondary-color: $primary;
$btn-secondary-hover-bg: $primary;
$btn-secondary-hover-color: $danger-light;


// Button neutral
$btn-neutral-bg: #F5F5F5;
$btn-neutral-border-color: #F5F5F5;
$btn-neutral-color: #C5C5C5;

// Font Size
$font-size-xs: 0.75rem; // 12px
$font-size-sm: 0.875rem; // 14px
$font-size-base: 1rem; // 16px
$font-size-md: 1.125rem; // 18px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-2xl: 2rem; // 32px

// Heading Tags
// $headings-color: $neutral-secondary;
$h1-font-size: 2.25rem; // 36px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.75rem; // 28px
$h4-font-size: 1.5rem; // 24px
$h5-font-size: 1.25rem; // 20px
$h6-font-size: 1rem; // 16px

// navbar
$navbar-vertical-open-width: 72px;

//avtar
$avatar-width-2xl: 97px;
$avatar-width-xl: 64px;
$avatar-width-lg: 56px;
$avatar-width: 52px;
$avatar-width-sm: 48px;
$avatar-width-xs: 32px;

$form-check-input-width: 1.4em;
$form-check-min-height: $form-check-input-width;


// Table
$table-color: $dark;
// $table-border-color: $light-blue;
$table-cell-padding-y: 8px;
$table-cell-padding-x: 16px;
$table-th-font-size: 12px;
$table-th-text-transform: capitalize;
$table-th-font-weight: 600;
$table-cell-font-size: 12px;


$input-font-size: 14px;

$custom-theme-colors: (
  "danger-light": $danger-light,
);

@import "@webpixels/css/core/maps";
@import "@webpixels/css/core/mixins";

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$utilities-border: map-merge($utilities-colors, $custom-theme-colors);
$utilities-border-color-values: map-loop($utilities-border, rgba-css-var, "$key", "border");

// Import Bootstrap / Webpixel
@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/extras";

@import "@webpixels/css/utilities";
$utilities: map-merge($utilities,
    ("font-weight": (property: font-weight,
        class: font,
        state: hover focus,
        values: $utilities-font-weight-values,
      ),
    ));

@import "bootstrap/scss/utilities/api";