// color
$primary: #0091F7;
$secondary: #F26343;
$gray: #8D8D8D;
$danger-light: #FEEFEC;
$dark :#353535;
$success: #4CAF50;




// $secondary: #0091F7;
// $primary: #0E2841;
// $danger: #F26343;
// $gray: #8D8D8D;
// $danger-light: #FEEFEC;