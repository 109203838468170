@import "../../../assets/style/color.scss";

.side-bar {
    min-width: 234px;
    top: 72px;

    .navbar-customization {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    display: flex;
                    padding: 8px;
                    height: 40px;
                    width: 40px;
                    border-radius: 4px;


                    &.active {
                        background-color: $primary;

                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }
        }
    }
}

.navbar--aside-customization {
    .nav-item {
        .nav-link {
            padding: 8px;

            &.active {
                background-color: #E6F4FE;
                color: $primary;
                border-right: 4px solid $primary;
            }
        }
    }
}