h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  --x-headings-color: #192432;
}

.hadder-bg {
  background-color: #0E2841;
}

.margin-top-72px {
  margin-top: 72px;
}

.btn-outline-dark {
  --x-btn-border-color: #E1E1E1;
}

p {
  color: $gray;
}

.New {
  background-color: #E4F3E5;
}

.bg-success-light {
  background-color: #E4F3E5;
}

.card-yellow-100 {
  background-color: #FFFCF1;
}

.Loyal {
  background-color: #FFFCF1;
}

.card-magenta-100 {
  background-color: #FAF5FF;
}

.card-pink-100 {
  background-color: #FFF4FA;
}

.card-orange-100 {
  background-color: #FEF1E5;
}

.card-red-100 {
  background-color: #FAE8E2;
}

.card-blue-100 {
  background-color: #F4FAFF;
}

.AtRisk {
  background-color: #FAE8E2;
}

.card-lotion-bg {
  background-color: #FBFBFB;
}

.rounded-color-input {
  border: 1px solid #ced4da;
  overflow: hidden;

  input {
    width: 20px;
    height: 20px;
    border-radius: 120px;
    padding: 0;
  }
}

.bg-gray-light-200 {
  background-color: #F5F5F5;
}

.width-360 {
  width: 360px;
}

.max-width-120 {
  max-width: 120px;
}

.swiper-image {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.swiper-edits-dots-groups {
  display: flex;
  align-items: center;
  gap: 3px;

  .swiper-edits-dots-active {
    height: 8px;
    width: 54px;
    background-color: $primary;
    border-radius: 50rem;

  }

  .swiper-edits-dots {
    height: 8px;
    width: 54px;
    background-color: #E7EAEC;
    border-radius: 50rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $primary;
    }
  }
}

.fillter-button {
  border: 1px solid $border-color;
  color: #353535;

  &:hover {
    border: 1px solid #FBCFC5;

  }

  &.active {
    border: 1px solid #FBCFC5;
    background-color: #FEEFEC;
    color: $secondary;
  }
}

.fillter-button-primary {
  border: 1px solid $border-color;
  color: #8D8D8D;

  &:hover {
    border: 1px solid $primary;

  }

  &.active {
    border: 1px solid $primary;
    background-color: #F4FAFF;
    color: $primary;
  }
}

.object-fit-cover {
  object-fit: cover;
}

.max-width-595 {
  max-width: 595px;
}

.text-48 {
  font-size: 48px;
}

.border-dash {
  border: 1px dashed #E7EAEC;
}

.secondary-radio {
  &.form-check-input {
    background-color: transparent;
    border-color: $secondary;

    &:checked {
      background-color: $secondary;
    }
  }
}

.success-checkbox {
  &.form-check-input {
    background-color: transparent;
    border-color: $success;

    &:focus {
      box-shadow: 0 0 0 3px rgba(0, 247, 54, 0.25);
    }

    &:checked {
      background-color: $success;
    }
  }
}

.purple-badge {
  background-color: #FAF5FF;
  color: #8F43DC;
}

.purple-progress-bar {
  .progress-bar {
    background-color: #8F43DC;
  }
}

.accordion-type-1 {
  .accordion-item {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #F5F5F5;

    .accordion-header {
      .accordion-button {
        padding: 16px 0px 16px 0px;
        font-size: 14px;
        font-weight: 600;
        color: $dark;
        box-shadow: none;

        &::after {
          background-image: url('../icons/chevron-down.svg');
          background-position: center;
          background-size: 16px;
          transform: rotate(180deg);
        }

        &.collapsed {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        padding: 16px 0px 16px 0px;
      }
    }
  }
}

.Cancelled {
  color: #D94F23;
  background-color: #FAE8E2;
  font-size: 12px;
}

.Completed {
  color: #4CAF50;
  background-color: #E4F3E5;
  font-size: 12px;
}

.Booked {
  color: #FFC107;
  background-color: #FFFCF1;
  font-size: 12px;
}

.noshow {
  color: #8F43DC;
  background-color: #FAF5FF;
  font-size: 12px;
}



.enquiry-status-select {
  .form-select {
    padding: 0px 22px 0px 4px;
    width: auto;
    background-position: right 8px center;
    border-radius: 2px;
  }
}

.irrelevant-class {
  background-color: $border-color;
  font-size: 12px;
}

.Irrelevant-class{
  background-color: $border-color;
  font-size: 12px;
}

.NotAnswering-class{
  background-color: #FFFCF1;
  font-size: 12px;
}

.junk-class {
  background-color: #E7EAEC;
  font-size: 12px;
}

.Junk-class{
  background-color: #E7EAEC;
  font-size: 12px;
}

.converted-class {
  background-color: #E4F3E5;
  font-size: 12px;
}

.Converted-class{
  background-color: #E4F3E5;
  font-size: 12px;
}

.new-class {
  background-color: #FFF4FA;
  font-size: 12px;
}

.New-class{
  background-color: #FFF4FA;
  font-size: 12px;
}

.follow-up-class {
  background-color: #E6F4FE;
  font-size: 12px;
}

.Followup-class{
  background-color: #E6F4FE;
  font-size: 12px;
}

.Lost {
  background-color: #E6F4FE;
  font-size: 12px;
}

.Vip {
  background-color: #FFF4FA;
  font-size: 12px;
}

.Referred {
  background-color: #E6F4FE;
  font-size: 12px;
}

.appointment-select {
  .form-select {
    padding: 6px 6px 6px 6px;
  }
}

.client-status {
  .form-select {
    padding: 0px 22px 0px 4px;
    font-size: 14px !important;
    width: auto;
    background-position: right 8px center;
    border-radius: 2px;
  }
}

.NeedAttention {
  background-color: #FAF5FF;
}
.Manual {
  background-color: #FFCA9B;
}
.Import {
  background-color: #FFCA9B;
}
//react-tel-input 
.special-label {
  display: none;
}

.form-select {
  background-position: right 12px center;

}

.form-select-icon-none {
  .form-select {
    background-image: none;
    padding: 0px 4px 0px 4px;
  }
}